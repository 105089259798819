import { useTranslation } from 'react-i18next';
import { useRef, useEffect } from 'react';
import {
  Button,
  Form,
  FormField,
  SlideOver,
  TextInput,
  SlideOverOnCloseProps,
  FormRefHandle,
  BaseSelect, TagWindowIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import {
  CostCatalogElementReadModel,
  EarningsCatalogElementReadModel,
  EarningsType,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel,
  TenantCatalogType,
  useApiCreateCatalogElementMutation,
  useApiGetCatalogElementCodeQuery,
} from '@client/shared/api';
import {
  NewCatalogElementFormValidationSchema,
  NewCatalogElementFormValidationValues,
} from './NewCatalogElementFormValidationSchema';
import { safeMutation } from '@client/shared/utilities';
import { getTranslatedEarningOptions } from '../../../utils';

interface CatalogElementCreateSlideOverProps extends SlideOverOnCloseProps {
  catalogId: string;
  parentElement?:
    | CostCatalogElementReadModel
    | RiskCatalogElementReadModel
    | EarningsCatalogElementReadModel
    | FinancingCatalogElementReadModel
    | null;
  type: TenantCatalogType;
}

export const CatalogElementCreateSlideOver = ({
  catalogId,
  parentElement,
  type,
  onClose,
}: CatalogElementCreateSlideOverProps) => {
  const { t } = useTranslation();

  const [postCreate, { isLoading: isCreating }] = useApiCreateCatalogElementMutation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<NewCatalogElementFormValidationValues>>(null);

  const { data: requestCode, isFetching: isRequestingCode } = useApiGetCatalogElementCodeQuery(
    { catalogId: catalogId, type: type, parentElementId: parentElement?.id },
    { skip: false },
  );

  const defaultFormValues = {
    code: '',
    name: '',
    earningsElementType: 'SaleRevenue' as EarningsType,
  };

  const earningOptions = getTranslatedEarningOptions();

  useEffect(() => {
    formRef?.current?.setValue('code', requestCode?.code);
  }, [requestCode]);

  const handleCatalogElementSubmit = async (data: NewCatalogElementFormValidationValues) => {
    if (formRef.current) {
      const values = formRef.current.getValues();
      try {
        await safeMutation(
          postCreate,
          {
            body: {
              parentId: parentElement?.id,
              catalogId: catalogId,
              type: type,
              code: values.code ?? '',
              name: values.name,
              earningsElementType: values.earningsElementType ?? 'SaleRevenue',
            },
          },
          isCreating,
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <Form<NewCatalogElementFormValidationValues>
      onSubmit={handleCatalogElementSubmit}
      validationSchema={NewCatalogElementFormValidationSchema}
      defaultValues={defaultFormValues}
      className="w-full flex flex-col justify-between h-full"
      ref={formRef}
    >
      {(isCreating || isRequestingCode) && (
        <LoadingIndicator text={t('common.loading')} mode="overlay" />
      )}
      <SlideOver.Header
        title={t('templates.NewCatalogTemplateElementTitle')}
        subTitle={t('templates.NewCatalogTemplateElementSubTitle')}
        backgroundClassName="bg-gray-600"
        onClose={() => onClose(false)}
      />
      <SlideOver.Content>
        <div className="m-8 bg-white">
          <div className="divide-gray-100 divide-y">
            <FormField name="code">
              {(control) => (
                <TextInput
                  label={t('common.code')}
                  disabled={isRequestingCode}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>

            <FormField name="name">
              {(control) => (
                <TextInput
                  label={t('common.description')}
                  icon={<TagWindowIcon />}
                  {...control}
                />
              )}
            </FormField>
            {type === 'Earnings' && (
              <FormField name="earningsElementType">
                {(control) => (
                  <BaseSelect
                    label={t('projectCalculate.earningsElementLabelEarningsType')}
                    options={earningOptions}
                    {...control}
                  />
                )}
              </FormField>
            )}
          </div>
        </div>
      </SlideOver.Content>
      <SlideOver.Controls>
        <div className="flex justify-end">
          <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
            {t('common.cancel')}
          </Button>
          <Button variant="primary" formSubmit={true} innerRef={submitRef}>
            {t('common.save')}
          </Button>
        </div>
      </SlideOver.Controls>
    </Form>
  );
};
