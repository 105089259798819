import { useTranslation } from 'react-i18next';
import React, { useState, useRef } from 'react';
import {
  Button,
  Form,
  FormField,
  SlideOver,
  TextInput,
  SlideOverOnCloseProps,
  FormRefHandle, Modal, BaseSelect, TagWindowIcon, LoadingIndicator,
} from '@client/shared/toolkit';
import {
  CatalogElementFormValidationSchema,
  CatalogElementFormValidationValues,
} from '../index';
import {
  CostCatalogElementReadModel, EarningsCatalogElementReadModel,
  FinancingCatalogElementReadModel,
  RiskCatalogElementReadModel, TenantCatalogReadModel, TenantCatalogType, useApiUpdateCatalogElementMutation
} from '@client/shared/api';
import { CatalogElementDeleteModal } from './CatalogElementDeleteModal';
import { safeMutation } from '@client/shared/utilities';
import { getTranslatedEarningOptions } from '../../../utils';

interface CatalogElementSlideOverProps extends SlideOverOnCloseProps {
  catalog: TenantCatalogReadModel
  parentElement?: CostCatalogElementReadModel | FinancingCatalogElementReadModel | RiskCatalogElementReadModel | EarningsCatalogElementReadModel | null;
  catalogElement?: CostCatalogElementReadModel | FinancingCatalogElementReadModel | RiskCatalogElementReadModel | EarningsCatalogElementReadModel | null;
  type: TenantCatalogType
}

export const CatalogElementEditSlideOver = ({
  catalog,
  parentElement,
  catalogElement,
  onClose,
  type
}: CatalogElementSlideOverProps) => {
  const { t } = useTranslation();

  const submitRef = useRef<HTMLButtonElement>(null);
  const formRef = useRef<FormRefHandle<CatalogElementFormValidationValues>>(null);

  const [sendUpdate, {isLoading: isUpdating}] = useApiUpdateCatalogElementMutation();

  const [showDelete, setShowDelete] = useState(false);

  const earningOptions = getTranslatedEarningOptions();

  const defaultFormValues = {
    parentId: parentElement?.id ?? null,
    code: catalogElement?.code ?? '',
    order: type === 'Costs' && catalogElement ? (catalogElement as CostCatalogElementReadModel).order : 0,
    description: catalogElement?.description ?? '',
    longDescription: catalogElement?.description ?? '',
    earningsType: type === 'Earnings' ? (catalogElement as EarningsCatalogElementReadModel)?.earningsType ?? 'SaleRevenue' : undefined,
    assignable: type === 'Finance' ? (catalogElement as FinancingCatalogElementReadModel)?.assignable ?? false : undefined,
    type: type
  };

  const handleCatalogSubmit = async (data: CatalogElementFormValidationValues) => {
    if (formRef.current) {
      const values = formRef.current.getValues();
      try {
        await safeMutation(
          sendUpdate,
          {
            body: {
              catalogId: catalog.id,
              elementId: catalogElement?.id ?? '',
              type: type,
              code: values.code ?? '',
              name: values.description,
              earningsElementType: values.earningsType ?? 'SaleRevenue'
            },
          },
          isUpdating,
        );
        onClose(true);
      } catch {
        /* left blank */
      }
    }
  };

  return (
    <>
      <Form<CatalogElementFormValidationValues>
        onSubmit={handleCatalogSubmit}
        validationSchema={CatalogElementFormValidationSchema}
        defaultValues={defaultFormValues}
        className="w-full flex flex-col justify-between h-full"
        ref={formRef}
      >
        {isUpdating && (
          <LoadingIndicator text={t('common.updating')} mode="overlay" />
        )}
        <SlideOver.Header
          title={catalogElement?.description ?? t('templates.NewCatalogTemplateElementTitle')}
          subTitle={catalogElement ? t('templates.UpdateCatalogTemplateElementSubTitle') : t('templates.NewCatalogTemplateElementSubTitle')}
          backgroundClassName="bg-gray-600"
          onClose={() => onClose(false)}
        />
        <SlideOver.Content>
          <div className="m-8 bg-white">
            <div className="divide-gray-100 divide-y">
              {parentElement?.id && (
                <>
                  <FormField name="parentId">
                    {(control) => (
                      <TextInput
                        label={t('templates.catalogParentElement')}
                        className="hidden"
                        disabled
                        {...control}
                      />
                    )}
                  </FormField>
                  <TextInput
                    label={t('templates.catalogParentElement')}
                    // className="hidden"
                    disabled
                    value={`${parentElement.code} - ${parentElement.description}`}
                  />
                </>
              )}

              {/* <FormField name="order">
                {(control) => (
                  <NumberInput
                    label={t('templates.catalogElementPosition')}
                    icon={<InputIcon src="/assets/icon-title.svg" />}
                    {...control}
                  />
                )}
              </FormField> */}
              <FormField name="code">
                {(control) => (
                  <TextInput
                    label={t('common.code')}
                    icon={<TagWindowIcon />}
                    disabled={!catalog.isTenantCatalog}
                    {...control}
                  />
                )}
              </FormField>
              <FormField name="description">
                {(control) => (
                  <TextInput
                    label={t('common.description')}
                    icon={<TagWindowIcon />}
                    disabled={!catalog.isTenantCatalog}
                    {...control}
                  />
                )}
              </FormField>
              {/* {type === 'Finance' && (
                <FormField name="assignable">
                  {(control) => (
                    <BooleanInput
                      label={t('templates.catalogElementAssignable')}
                      {...control}
                    />
                  )}
                </FormField>
              )} */}
              {type === 'Earnings' && (
                <FormField name="earningsType">
                  {(control) => (
                    <BaseSelect
                      label={t('projectCalculate.earningsElementLabelEarningsType')}
                      options={earningOptions}
                      disabled={catalog.isTenantCatalog}
                      {...control}
                    />
                  )}
                </FormField>
              )}
            </div>
          </div>
        </SlideOver.Content>
        <SlideOver.Controls>
          {catalogElement?.id && catalog.isTenantCatalog && (
            <div className="flex flex-grow flex-row">
              <Button variant="warning" onClick={() => setShowDelete(true)}>
                {t('common.delete')}
              </Button>
            </div>
          )}
          <div className="flex justify-end">
            <Button variant="secondary" className="mr-2" onClick={() => onClose(false)}>
              {t('common.cancel')}
            </Button>
            {catalog.isTenantCatalog &&
            <Button variant="primary" formSubmit={true} innerRef={submitRef}>
              {t('common.save')}
            </Button>
            }
          </div>
        </SlideOver.Controls>
      </Form>
      <Modal isOpen={showDelete} onClose={() => setShowDelete(false)}>
        {catalogElement && (
          <CatalogElementDeleteModal
            catalogId={catalog.id}
            type={type}
            catalogElement={catalogElement}
            onClose={() => setShowDelete(false)}
          />
        )}
      </Modal>
    </>
  );
};
