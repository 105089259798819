import { Button, Modal, TextInput } from '@client/shared/toolkit';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { WorkflowTaskStatus } from '@client/shared/api';
import { isEmpty } from '@client/shared/utilities';

interface InvoiceReviewCommentModalProps {
  reviewInvoice: (comment: string) => void;
  onClose: () => void;
  completionStatus: WorkflowTaskStatus | null
}

export const InvoiceReviewCommentModal = ({
  reviewInvoice,
  onClose,
  completionStatus
}: InvoiceReviewCommentModalProps) => {
  const { t } = useTranslation();

  const [comment, setComment] = useState('');

  const getTitle = (): string => {
    if(completionStatus === 'Declined')
      return t('projectControl.addCommentDeclined');

    if(completionStatus === 'Rejected')
      return t('projectControl.addCommentRejection');

    return t('projectControl.addComment');
  }

  const handleAddComment = () => {
      reviewInvoice(comment);
      onClose();
  };

  return (
    <>
      <Modal.Header
        title={getTitle()}
        description={t('projectControl.addCommentDescription')}
      />
      <Modal.Content className="">
        <TextInput
          label={t('projectControl.comment')}
          value={comment}
          onChange={(value) => setComment(value)}
          inputType="textarea"
          className='min-h-40'
          isValidationValid={!isEmpty(comment)}
        />
      </Modal.Content>
      <Modal.Controls className="bg-white">
        <Button onClick={onClose} variant="secondary">
          {t('common.cancel')}
        </Button>
        <Button variant="primary" 
          disabled={completionStatus === 'Declined' && isEmpty(comment)}
          onClick={handleAddComment}>
          {t('common.proceed')}
        </Button>
      </Modal.Controls>
    </>
  );
};
