import { Layout } from 'react-grid-layout';
import {
  DashboardWidgetAssets,
  DashboardWidgetCalculateBudgetTable,
  DashboardWidgetCashFlowChart,
  DashboardWidgetCashOutPlan,
  DashboardWidgetFinancingControlling,
  DashboardWidgetIsShouldChart,
  DashboardWidgetKPI,
  DashboardWidgetMaBVRate,
  DashboardWidgetMapView,
  DashboardWidgetMixed,
  DashboardWidgetPlaceholder,
  DashboardWidgetProjectCustomFields,
  DashboardWidgetProjectInformation,
  DashboardWidgetProjectMetadata,
  DashboardWidgetProjectMilestones,
  DashboardWidgetRiskMatrix,
  DashboardWidgetRisksList,
  DashboardWidgetTasks,
  DashboardWidgetTextBox,
  DashboardWidgetTimeline,
  DashboardWidgetTrafficLight,
  DashboardWidgetType,
  WidgetConfig,
} from '..';
import { ProjectReadModel } from '@client/shared/api';
import { DashboardWidgetProjectUdfs } from '../Widgets/DashboardWidgetProjectUdfs';

export const getWidget = (
  layoutItem: WidgetConfig,
  variant: 'card' | 'child' = 'card',
  projectData: ProjectReadModel,
  multiProject: boolean,
  layout?: Layout,
  onClickCallback?: (layoutItem: WidgetConfig, layout?: Layout) => void,
) => {
  switch (layoutItem?.type) {
    //---- CARESTONE DASHBOARD WIDGETS ----
    case DashboardWidgetType.ProjectCustomFields:
      return (
        <DashboardWidgetProjectCustomFields
          config={layoutItem.config}
          variant={variant}
          title={layoutItem.title}
          icon={layoutItem.icon}
        >
          {layoutItem.widgets?.length && (
            <DashboardWidgetMixed
              widgets={layoutItem.widgets}
              variant="child"
              projectData={projectData}
              multiProject={multiProject}
            >
              {layoutItem.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectCustomFields>
      );
    case DashboardWidgetType.ProjectMetaDataAddress:
      return <DashboardWidgetProjectMetadata type="address" />;
    case DashboardWidgetType.ProjectMetaDataCustom:
      return (
        <DashboardWidgetProjectMetadata
          type="custom"
          variant={variant}
          config={layoutItem.config ? layoutItem.config[0] : undefined}
        >
          {layoutItem.widgets?.length && (
            <DashboardWidgetMixed
              widgets={layoutItem.widgets}
              variant="child"
              projectData={projectData}
              multiProject={multiProject}
            >
              {layoutItem.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMetadata>
      );
    case DashboardWidgetType.ProjectMetaDataGrz:
      return (
        <DashboardWidgetProjectMetadata type="grz" variant={variant}>
          {layoutItem.widgets?.length && (
            <DashboardWidgetMixed
              widgets={layoutItem.widgets}
              variant="child"
              projectData={projectData}
              multiProject={multiProject}
            >
              {layoutItem.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMetadata>
      );
    case DashboardWidgetType.MaBVRate:
      return <DashboardWidgetMaBVRate />;
    case DashboardWidgetType.Assets:
      return <DashboardWidgetAssets />;
    case DashboardWidgetType.CashFlowChart:
      return <DashboardWidgetCashFlowChart />;
    case DashboardWidgetType.IsShouldChart:
      return <DashboardWidgetIsShouldChart />;
    case DashboardWidgetType.ProjectMilestones:
      return (
        <DashboardWidgetProjectMilestones config={layoutItem.config} variant={variant}>
          {layoutItem.widgets?.length && (
            <DashboardWidgetMixed
              widgets={layoutItem.widgets}
              variant="child"
              projectData={projectData}
              multiProject={multiProject}
            >
              {layoutItem.children}
            </DashboardWidgetMixed>
          )}
        </DashboardWidgetProjectMilestones>
      );
    case DashboardWidgetType.Mixed:
      return (
        <DashboardWidgetMixed widgets={layoutItem.widgets ?? []} projectData={projectData} multiProject={multiProject}>
          {layoutItem.children}
        </DashboardWidgetMixed>
      );
    case DashboardWidgetType.FinancialControlling:
      return (
        <DashboardWidgetFinancingControlling widget={layoutItem}>
          {layoutItem.children}
        </DashboardWidgetFinancingControlling>
      );

    //---- REPORT / CUSTOM DASHBOARD WIDGETS ----
    case DashboardWidgetType.CalculateBudgetTable:
      return <DashboardWidgetCalculateBudgetTable widget={layoutItem} />;
    case DashboardWidgetType.CashOutPlan:
      return <DashboardWidgetCashOutPlan widget={layoutItem} layout={layout} />;
    case DashboardWidgetType.KPI:
      return <DashboardWidgetKPI widget={layoutItem} />;
    case DashboardWidgetType.MapView:
      return <DashboardWidgetMapView widget={layoutItem} projectData={projectData} multiProject={multiProject} layout={layout} />;
    case DashboardWidgetType.ProjectInformation:
      return <DashboardWidgetProjectInformation widget={layoutItem} layout={layout} />;
    case DashboardWidgetType.ProjectUdfs:
      return <DashboardWidgetProjectUdfs widget={layoutItem} />;
    case DashboardWidgetType.Tasks:
      return <DashboardWidgetTasks widget={layoutItem} />;
    case DashboardWidgetType.TextBox:
      return <DashboardWidgetTextBox widget={layoutItem} />;
    case DashboardWidgetType.Timeline:
      return <DashboardWidgetTimeline widget={layoutItem} />;
    case DashboardWidgetType.TrafficLight:
      return <DashboardWidgetTrafficLight widget={layoutItem} />;
    case DashboardWidgetType.RisksList:
      return <DashboardWidgetRisksList widget={layoutItem} />;
    case DashboardWidgetType.RiskMatrix:
      return <DashboardWidgetRiskMatrix widget={layoutItem} />;

    //---- PLACEHOLDER ----
    case DashboardWidgetType.Placeholder:
      return (
        <DashboardWidgetPlaceholder
          handleOnClick={
            onClickCallback
              ? (e) => {
                  e.preventDefault();
                  e.stopPropagation();
                  onClickCallback(layoutItem, layout);
                }
              : undefined
          }
        />
      );
    default:
      return '';
  }
};
