import { useNavigate, useParams } from 'react-router-dom';
import { LoadingIndicator, SlideOver } from '@client/shared/toolkit';
import React, { useContext, useEffect, useState } from 'react';
import { ROUTES_CONFIG } from '@client/shared/permissions';
import { useLoadedProjectId } from '@client/project/store';
import { useTranslation } from 'react-i18next';
import { InvoiceDocumentReview, InvoiceEditContext } from '@client/project/shared';
import { AiEvalDocumentReadModel, useApiGetAiEvalDocumentsQuery } from '@client/shared/api';

export const InvoiceDocumentView = () => {
  const { documentId } = useParams();

  const navigate = useNavigate();
  const { t } = useTranslation();

  const loadedProjectId = useLoadedProjectId();

  const { setSlideOverOpen } = useContext(InvoiceEditContext);

  const [isOpenInvoiceDocumentSlideOver, setIsOpenInvoiceDocumentSlideOver] = useState(false);
  const [documents, setDocuments] = useState<AiEvalDocumentReadModel[] | null>(null)

  if (!documentId) {
    navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
  }

  const {
    data: fetchedInvoiceDocuments,
    isFetching: isFetchingInvoiceDocuments,
    isError,
  } = useApiGetAiEvalDocumentsQuery(
    {
      projectId: loadedProjectId ?? 'unset',
    },
    {
      skip: !loadedProjectId || !!documents
    },
  );

  useEffect(() => {
    if (typeof fetchedInvoiceDocuments !== 'undefined' && fetchedInvoiceDocuments !== null && fetchedInvoiceDocuments) {
      setDocuments(fetchedInvoiceDocuments)
      setIsOpenInvoiceDocumentSlideOver(true);
    }
    if (isError) {
      navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchedInvoiceDocuments, isError]);

  useEffect(() => {
    setSlideOverOpen(isOpenInvoiceDocumentSlideOver);
  }, [isOpenInvoiceDocumentSlideOver, setSlideOverOpen]);

  return (
    <SlideOver
      isOpen={isOpenInvoiceDocumentSlideOver}
      onClose={() => setIsOpenInvoiceDocumentSlideOver(false)}
      variant="2xl"
      confirmBeforeClose={false}
      onAfterLeave={() => {
        setDocuments(null)
        navigate(ROUTES_CONFIG.PROJECT_CONTROL.name);
      }}
    >
      {isFetchingInvoiceDocuments && (
        <LoadingIndicator text={t('projectControl.fetchingInvoiceLoadingIndicator')} mode="overlay" />
      )}
      <InvoiceDocumentReview
        onClose={() => setIsOpenInvoiceDocumentSlideOver(false)}
      />
    </SlideOver>
  );
};
