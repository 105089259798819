import { AdvancedMarker, APIProvider, Map, useMap, useMapsLibrary } from '@vis.gl/react-google-maps';
import { settings } from '@client/shared/store';
import { HintBox } from '../HintBox';
import { useTranslation } from 'react-i18next';
import { ReactElement, useCallback, useEffect, useId, useState } from 'react';
import { AddressAutocomplete } from './AddressAutocomplete';
import { ProjectReadModel } from '@client/shared/api';

interface AddressMapProps {
  onAddressChange: (address: Record<string, string>) => void;
  project: ProjectReadModel;
  lat?: string;
  lng?: string;
  apiKey?: string;
  height?: number;
  marker?: ReactElement;
}

interface MapContainerProps {
  lat?: string;
  lng?: string;
  project: ProjectReadModel;
  onAddressChange: (address: Record<string, string>) => void;
  height?: number;
  marker?: ReactElement;
}

const MapContainer = (props: MapContainerProps) => {
  const { lat, lng, project, onAddressChange, height, marker } = props;
  const mapId = useId();

  const { t } = useTranslation();
  const map = useMap();
  const geocodingLibrary = useMapsLibrary('geocoding');
  const [center, setCenter] = useState<google.maps.LatLngLiteral | null>(null);
  const [mapAddress, setMapAddress] = useState<string>("");

  const geocodeAddress = useCallback(async (address: Record<string, string | google.maps.LatLngLiteral>) => {
    if (!geocodingLibrary) return null;

    const geocoder = new geocodingLibrary.Geocoder();

    try {
      const geocoding = await geocoder.geocode(address);
      
      if (geocoding.results) {
        return geocoding.results[0];
      } else {
        return null;
      }
    } catch (err) {
      console.log(err);
      return null;
    }
  }, [geocodingLibrary]);

  useEffect(() => {
    if (!geocodingLibrary) return;

    const fetchAddress = async () => {
      if (lat && lng) {
        const newCenter = { lat: parseFloat(lat), lng: parseFloat(lng) };
        setCenter(newCenter);
        const address = await geocodeAddress({ location: newCenter });

        if (address && address.geometry) {
          setMapAddress(address.formatted_address);
          // setMapAddress(generateAddressName(address.address_components));
          onAddressChange({ lat: address.geometry.location.lat().toFixed(6).toString(), lng: address.geometry.location.lng().toFixed(6).toString() });
        }
      } else {
        const projectAddress = [
          project.payload.street, 
          project.payload.number, 
          project.payload.city, 
          project.payload.countryCode
        ].filter(Boolean).join(", ");

        const address = await geocodeAddress({ address: projectAddress });

        if (address && address.geometry) {
          setCenter({ lat: address.geometry.location.lat(), lng: address.geometry.location.lng() });
          setMapAddress(address.formatted_address);
          // setMapAddress(generateAddressName(address.address_components));
          onAddressChange({ lat: address.geometry.location.lat().toFixed(6).toString(), lng: address.geometry.location.lng().toFixed(6).toString() });
        }
      }
    };

    fetchAddress();
  }, [geocodingLibrary, geocodeAddress, lat, lng, onAddressChange, project.payload.city, project.payload.countryCode, project.payload.number, project.payload.street]);

  const onPlaceSelect = (place: google.maps.places.PlaceResult) => {
    if (map && place?.geometry?.location && place.formatted_address) {
      const newCenter = { lat: place.geometry.location.lat(), lng: place.geometry.location.lng() };
      setCenter(newCenter);
      map.panTo(newCenter);
      setMapAddress(place.formatted_address)
      // setMapAddress(generateAddressName(place.address_components));
      onAddressChange({ lat: newCenter.lat.toFixed(6).toString(), lng: newCenter.lng.toFixed(6).toString() });
    }
  };

  const onDragEnd = (place: google.maps.MapMouseEvent) => {
    if (map && place?.latLng) {
      geocodeAddress({ location: { lat: place.latLng.lat(), lng: place.latLng.lng() } }).then((address) => {
        if (address && place?.latLng) {
          const newCenter = { lat: place.latLng.lat(), lng: place.latLng.lng() };
          setCenter(newCenter);
          map.panTo(newCenter);
          setMapAddress(address.formatted_address);
          // setMapAddress(generateAddressName(address.address_components));
          onAddressChange({ lat: place.latLng.lat().toFixed(6).toString(), lng: place.latLng.lng().toFixed(6).toString() });
        } else {
          console.log("ERROR: Cant't find location");
        }
      });
    }
  };

  // const generateAddressName = (addressComponents: google.maps.GeocoderAddressComponent[]) => {
  //   const componentsMap = addressComponents.reduce((acc, component) => {
  //     component.types.forEach((type) => {
  //       acc[type] = component.long_name;
  //     });
  //     return acc;
  //   }, {} as Record<string, string>);
  
  //   const streetNumber = componentsMap["street_number"] || "";
  //   const route = componentsMap["route"] || "";
  //   const postalCode = componentsMap["postal_code"] || "";
  //   const city = componentsMap["locality"] || "";
  
  //   return `${route}${streetNumber ? ` ${streetNumber}` : ""}, ${postalCode}, ${city}`;
  // };

  const handleAddressInputChange = (newAddress: string) => {
    setMapAddress(newAddress);
  };

  return center ? (
    <div className={`relative h-[${height ? height : 250}px] flex-shrink-0 border`}>
      <Map
        defaultZoom={16}
        mapId={mapId}
        defaultCenter={center}
        gestureHandling={'greedy'}
        disableDefaultUI={true}
      >
        <AdvancedMarker position={center} draggable={true} onDragEnd={onDragEnd}>
          {marker || <img src="/assets/icon-map-marker.svg" width={32} height={32} alt="Map marker" />}
        </AdvancedMarker>
      </Map>
      <AddressAutocomplete value={mapAddress} onPlaceSelect={onPlaceSelect} onChange={handleAddressInputChange} />
    </div>
  ) : (
    <HintBox hintType="danger" title={t('dashboard.widget.mapView.missingCoordinatesError.title')}>
      {t('dashboard.widget.mapView.missingCoordinatesError.content')}
    </HintBox>
  )
};

export const AddressMap = ({ project, lat, lng, onAddressChange, apiKey, height, marker }: AddressMapProps) => {
  const { t } = useTranslation();
  const mapsApiKey = apiKey || settings.googleMapsApiKey || "";

  return (mapsApiKey ? (
    <APIProvider apiKey={mapsApiKey}>
      <MapContainer lat={lat} lng={lng} project={project} onAddressChange={onAddressChange} height={height} marker={marker} />
    </APIProvider>) : 
    <HintBox hintType="danger" title={t('dashboard.widget.mapView.missingApiKeyError.title')}>
      {t('dashboard.widget.mapView.missingApiKeyError.content')}
    </HintBox>
  );
};
