import React, { PropsWithChildren, ReactNode } from 'react';
import { SlideOverSortableListItem } from './SlideOverSortableListItem';
import cn from 'classnames';
import { SlideOverSortableListHeaderItem } from './SlideOverSortableListHeaderItem';
import { SlideOverSortableListTotalListItem } from './SlideOverSortableListTotalListItem';

export type SortableListItemCol = {
  value?: string | ReactNode;
  title?: string;
  header?: string;
};

export interface SlideOverSortableListItemData {
  id: string;
  name?: string | ReactNode;
  description?: string | ReactNode;
  icon?: string | ReactNode;
  cols?: SortableListItemCol[];
  className?: string;
  contextMenu?: ReactNode;
  children?: ReactNode;
  childItems?: SlideOverSortableListItemData[];
}

export interface SlideOverSortableListProps extends PropsWithChildren {
  level?: number;
  headline?: string | ReactNode;
  icon?: ReactNode;
  data: SlideOverSortableListItemData[];
  handleSelect: (i: number, id: string, level: number) => void;
  color?: string;
  subTotals?: (string | ReactNode)[];
  textColor?: string;
  onHandleSort?: (pos: number) => void;
  noItemsMessage?: string;
  sortHeader: {
    asc: boolean | null;
    label: string;
  }[];
  gridCols?: string;
  subTotalLabel?: string;
  subTotalSumLabel?: string;
  subTotalHeaders?: string[];
  showSumOnColumns?: number[];
  contextMenu?: boolean;
  earlyCompact?: boolean;
  additionalTopChildren?: ReactNode;
  bgColor?: string;
}

export const SlideOverSortableList = (props: SlideOverSortableListProps) => {
  const {
    level = 0,
    headline,
    icon,
    data,
    handleSelect,
    color,
    subTotals,
    textColor = 'text-primary',
    onHandleSort,
    noItemsMessage,
    sortHeader,
    gridCols = 'grid-cols-2 md:grid-cols-4',
    subTotalLabel,
    subTotalSumLabel,
    subTotalHeaders,
    showSumOnColumns,
    children,
    contextMenu = false,
    earlyCompact = false,
    additionalTopChildren,
    bgColor,
  } = props;
  return (
    <div className={cn(bgColor ?  bgColor: 'bg-white', headline ? 'pt-3' : '')}>
      <div
        className={cn('flex px-6 border-b pb-1.5', {
          'mb:pb-0': !earlyCompact,
          'lg:pb-0': earlyCompact,
        })}
      >
        <div
          className={cn('w-full  flex-none flex gap-3  items-center ', {
            'md:w-1/3 md:gap-6 md:pb-1.5 md:pr-3': !earlyCompact,
            'lg:w-1/3 lg:gap-6 lg:pb-1.5 lg:pr-3': earlyCompact,
          })}
        >
          <div className="w-8 flex-none flex justify-center items-center">{icon}</div>
          {headline && (
            <h5
              className={cn({
                'font-light text-[22px] text-stone-600': level === 0,
                'font-bold text-lg text-slate-500': level === 1,
              })}
            >
              {headline}
            </h5>
          )}
        </div>
        <div
          className={cn(
            'hidden text-[13px] gap-3',
            {
              'md:flex-1 md:grid md:text-right md:justify-between md:pl-3 ': !earlyCompact,
              'lg:flex-1 lg:grid lg:text-right lg:justify-between lg:pl-3 ': earlyCompact,
            },
            gridCols,
          )}
        >
          {sortHeader &&
            sortHeader.map((item, i) => (
              <SlideOverSortableListHeaderItem
                key={`slide-over-list-sort-label-${i}`}
                label={item.label}
                asc={item.asc}
                alignRight
                onClick={onHandleSort ? () => onHandleSort(i) : undefined}
              />
            ))}
        </div>
        {contextMenu && <div className="w-8 flex-none" />}
      </div>
      {additionalTopChildren}
      {children ? (
        <div className="relative">
          {color && (
            <div className={cn('absolute -left-1 w-2 rounded h-[calc(100%+0.8rem)] -top-[0.4rem] z-10', color)}></div>
          )}
          {children}
        </div>
      ) : (
        <>
          {data.length > 0 && (
            <div className="relative">
              {color && (
                <div className={cn('absolute -left-1 w-2 rounded h-[calc(100%+0.8rem)] -top-[0.4rem] z-10', color)}></div>
              )}
              <ul className="w-full">
                {data.map((item, i) => {
                  return (
                    <li
                      className={cn('w-full border-b last:border-b-0', item.className)}
                      key={`slide-over-list-item-${item.id}-${i}`}
                    >
                      <SlideOverSortableListItem
                        item={item}
                        gridCols={gridCols}
                        level={level}
                        earlyCompact={earlyCompact}
                        onClick={handleSelect}
                        index={i}
                      />
                    </li>
                  );
                })}
              </ul>
            </div>
          )}
        </>
      )}
      {!data.length && noItemsMessage && (
        <div className={cn('px-6 pt-3 pb-2')}>
          <span>{noItemsMessage}</span>
        </div>
      )}
      {subTotals && (
        <SlideOverSortableListTotalListItem
          nrOfItems={data.length}
          totals={subTotals}
          textColor={textColor}
          totalHeaders={subTotalHeaders ?? []}
          gridCols={gridCols}
          totalSumLabel={subTotalSumLabel}
          showSumOnColumns={showSumOnColumns}
          totalLabel={subTotalLabel}
          contextMenu={contextMenu}
          earlyCompact={earlyCompact}
        />
      )}
    </div>
  );
};
