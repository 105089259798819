import { ReactNode, useCallback, useEffect, useMemo, useState } from 'react';
import { Layout, Layouts, Responsive, WidthProvider } from 'react-grid-layout';
import 'react-grid-layout/css/styles.css';
import {
  ProjectDashboardCustomDataType,
  ProjectReadModel,
  UnitSystem,
  useApiGetProjectQuery,
  UpsertTrafficLightPayload
} from '@client/shared/api';
import { Button, MaximizeIcon, Modal, PencilIcon } from '@client/shared/toolkit';
import { WidgetDashboardWizard } from './WidgetDashboardWizard';
import { WidgetDashboardEdit } from './WidgetDashboardEdit';
import {
  areLayoutsEqual,
  dashboardConfig,
  DashboardConfigKeys,
  DashboardWidgetType,
  fillEmptyCells,
  findWidget,
  getWidget,
  prepareMobileBreakpoints,
  WidgetSizeType,
} from './utils';
import cn from 'classnames';
import { useLoadedProjectId, useUnitSystem } from '@client/project/store';
import { useTranslation } from 'react-i18next';

export type WidgetPosition = {
  x: number;
  y: number;
}

export interface DashboardWidgetProps {
  widget: WidgetConfig;
  layout?: Layout;
}

export type DashboardWidgetConfig = {
  name: string;
  icon: ReactNode;
  title?: string;
  size?: 'large' | 'default';
  variant?: 'feed' | 'other';
  hideIfEmpty?: boolean;
  type?: ProjectDashboardCustomDataType;
};

export type DashboardWidgetVariant = 'card' | 'child';

export interface Widget {
  widget: WidgetConfig;
  layout: Layout;
  breakpoints?: { [key: string]: Layout };
}

export interface WidgetConfig {
  type: DashboardWidgetType;
  id?: string;
  name?: string;
  title?: string;
  description?: string;
  col?: number;
  order?: number;
  colSpan?: string;
  config?: DashboardWidgetConfig[];
  variant?: string;
  icon?: ReactNode | string;
  children?: ReactNode | string;
  sizes?: WidgetSizeType[];
  widgets?: WidgetConfig[];
  text?: string; // textbox widget
  lat?: string; // map widget
  lng?: string; // map widget
  trafficLights?: UpsertTrafficLightPayload[]; // traffic lights widget
}

interface InvalidGridItems {
  sourceItem: Layout;
  targetItems: Layout[]
}

export interface WidgetDashboardProps {
  type?: DashboardConfigKeys;
  layout: Widget[];
  isEditable?: boolean;
  onSave?: (widgets: Widget[]) => void;
  dashboardId?: string;
  dashboardName?: string;
  multiProject?: boolean;
}

export const WidgetDashboard = (props: WidgetDashboardProps) => {
  const { type = 'default', layout, isEditable = true, onSave, dashboardId, dashboardName, multiProject = false } = props;

  const { t } = useTranslation();
  const unitSystem = useUnitSystem();
  const loadedProjectId = useLoadedProjectId();
  const { data: projectData } = useApiGetProjectQuery(
    {
      projectId: loadedProjectId || '',
      unitSystem: unitSystem as UnitSystem,
    },
    { skip: loadedProjectId == null },
  );

  const [gridLayout, setGridLayout] = useState<Layouts | null>(null);
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wizardConfig, setWizardConfig] = useState<Layout | null>(null);
  const [isEditOpen, setIsEditOpen] = useState(false);
  const [isExpandOpen, setIsExpandOpen] = useState(false);
  const [widgetToEdit, setWidgetToEdit] = useState<Widget | null>(null);
  const [widgetToResize, setWidgetToResize] = useState<Widget | null>(null);
  const [widgetToExpand, setWidgetToExpand] = useState<Widget | null>(null);
  const [currentBreakpoint, setCurrentBreakpoint] = useState<string>('lg');
  const [isInteracting, setIsInteracting] = useState<boolean>(false);
  const [isCollisionModalOpen, setIsCollisionModalOpen] = useState(false);
  const [invalidGridItems, setInvalidGridItems] = useState<InvalidGridItems | null>(null);

  const ResponsiveReactGridLayout = useMemo(() => WidthProvider(Responsive), []);

  const gridConfig = useMemo(() => {
    return dashboardConfig[type];
  }, [type]);

  useEffect(() => {
    const breakpoints: {[key: string]: Layout[]} = {
      lg: []
    };

    if (gridConfig.breakpoints) {
      Object.keys(gridConfig.breakpoints).forEach((gridBreakpoint) => {
        breakpoints[gridBreakpoint] = [];
      });
    }

    layout.forEach((widget) => {
      Object.keys(breakpoints).forEach((breakpoint) => {
        const config = widget.breakpoints?.[breakpoint] || widget.layout;

        breakpoints[breakpoint].push({
          isResizable: isEditable,
          isDraggable: isEditable,
          ...config,
        });
      });
    });

    setGridLayout(type === 'carestone' ? breakpoints : prepareMobileBreakpoints(breakpoints, type));
  }, [layout, gridConfig.breakpoints, isEditable, type]);

  const saveLayout = useCallback(
    (newLayout: Layout[]) => {
      if (onSave && isEditable) {
        // filter out the placeholder widgets, they don't need to be saved
        const copyOldLayout = [...layout];
        const notPlaceholderWidgets: Widget[] = copyOldLayout
          .filter((widgetItem) => {
            return widgetItem.widget.type !== DashboardWidgetType.Placeholder;
          })
          .map((widgetItem) => {
            const newPosition = newLayout.find((layoutItem) => layoutItem.i === widgetItem.widget.id);
            return {
              layout: newPosition as Layout, // override the new position
              widget: widgetItem.widget,
            };
          });
        onSave(notPlaceholderWidgets);
      }
    },
    [layout, onSave, isEditable],
  );

  const rollbackChange = useCallback(() => {
    if (invalidGridItems && gridLayout) {
      const updatedLayout = gridLayout.lg.map((gridItem) => {
        if (gridItem.i === invalidGridItems.sourceItem.i) {
          gridItem = invalidGridItems.sourceItem;
        }
      
        return gridItem;
      });

      setIsCollisionModalOpen(false);
      setGridLayout({ ...gridLayout, lg: updatedLayout });
    }
  }, [invalidGridItems, gridLayout, setGridLayout, setIsCollisionModalOpen]);

  const confirmConflict = useCallback(() => {
    if (!invalidGridItems || !gridLayout) return;

    const { lg: currentLayout } = gridLayout;
    const { targetItems } = invalidGridItems;

    const filteredLayout = currentLayout.filter(
      (gridItem) => !targetItems.some((targetItem) => targetItem.i === gridItem.i)
    );
    const updatedLayout = fillEmptyCells(filteredLayout, gridConfig.rows, gridConfig.cols?.lg);

    setIsCollisionModalOpen(false);
    setInvalidGridItems(null);

    if (!areLayoutsEqual(currentLayout, updatedLayout)) {
      saveLayout(updatedLayout);
    }
  }, [gridLayout, invalidGridItems, saveLayout, gridConfig.rows, gridConfig.cols?.lg]);

  const findOverlappedItems = useCallback((resizedItem: Layout, currentLayout: Layout[]) => {
    return currentLayout.filter((item) => {
      if (item.i === resizedItem.i) return false;

      return (
        resizedItem.x < item.x + item.w &&
        resizedItem.x + resizedItem.w > item.x &&
        resizedItem.y < item.y + item.h &&
        resizedItem.y + resizedItem.h > item.y
      );
    });
  }, []);

  const resetSelectedWidget = useCallback(() => {
    setIsEditOpen(false);
    setWizardConfig(null);
    setWidgetToEdit(null);
  }, []);

  const onDragStop = useCallback((currentLayout: Layout[], oldItem: Layout, newItem: Layout) => {
    setIsInteracting(false);

    const overlappedItems = findOverlappedItems(newItem, currentLayout);
    const overlappedWidgets = overlappedItems.filter((overlappedItem) => !overlappedItem.static);

    if (overlappedItems) {
      if (overlappedItems.length === 1 && newItem.w === overlappedItems[0].w && newItem.h === overlappedItems[0]?.h) {
        const updatedLayout = currentLayout.map((item) => {
          if (item.i === newItem.i) {
            return { ...item, x: overlappedItems[0].x, y: overlappedItems[0].y };
          }
          if (item.i === overlappedItems[0].i) {
            return { ...item, x: oldItem.x, y: oldItem.y };
          }
          return item;
        });

        if (gridLayout && !areLayoutsEqual(gridLayout.lg, updatedLayout)) {
          saveLayout(updatedLayout);
        }
      } else if (overlappedWidgets.length > 0) {
        setInvalidGridItems({
          sourceItem: oldItem,
          targetItems: overlappedWidgets
        });
        setIsCollisionModalOpen(true);
        setGridLayout({ ...gridLayout, lg: currentLayout });
      } else {
        const updatedLayout = fillEmptyCells(currentLayout, gridConfig.rows, gridConfig.cols?.lg);
        if (gridLayout && !areLayoutsEqual(gridLayout.lg, updatedLayout)) {
          saveLayout(updatedLayout);
        }
      }
    }
  }, [findOverlappedItems, gridLayout, saveLayout, gridConfig.rows, gridConfig.cols?.lg]);

  const onResizeStart = useCallback((
    _newLayout: Layout[],
    oldItem: Layout
  ) => {
    setIsInteracting(true);
    const widgetToResize = layout.find(({ widget }) => widget.id === oldItem.i);
    widgetToResize && setWidgetToResize(widgetToResize);
  }, [layout]);

  const onResize = useCallback((
    _newLayout: Layout[],
    oldItem: Layout,
    newItem: Layout,
    placeholder: Layout
  ) => {
    const isResizeAllowed = widgetToResize?.widget?.sizes?.some(
      (size: { w: number; h: number }) => newItem.w === size.w && newItem.h === size.h
    );

    if (!isResizeAllowed) {
      const resetSize = { w: oldItem.w, h: oldItem.h };
      Object.assign(newItem, resetSize);
      Object.assign(placeholder, resetSize);
    }
  }, [widgetToResize?.widget?.sizes]);

  const onResizeStop = useCallback((currentLayout: Layout[], oldItem: Layout, newItem: Layout) => {
    setWidgetToResize(null);
    setIsInteracting(false);

    const overlappedItems = findOverlappedItems(newItem, currentLayout);
    const overlappedWidgets = overlappedItems.filter((overlappedItem) => !overlappedItem.static);

    if (overlappedWidgets.length > 0) {
      setInvalidGridItems({
        sourceItem: oldItem,
        targetItems: overlappedWidgets
      });
      setIsCollisionModalOpen(true);
      setGridLayout({ ...gridLayout, lg: currentLayout });
    } else {
      let updatedLayout = currentLayout.filter(
        (item) => !overlappedItems.some((overlappedItem) => overlappedItem.i === item.i)
      );
  
      if (newItem.w < oldItem.w || newItem.h < oldItem.h) {
        updatedLayout = fillEmptyCells(updatedLayout, gridConfig.rows, gridConfig.cols?.lg)
      }

      if (gridLayout && !areLayoutsEqual(gridLayout.lg, updatedLayout)) {
        saveLayout(updatedLayout);
      }
    }
  }, [findOverlappedItems, gridLayout, saveLayout, gridConfig.rows, gridConfig.cols?.lg]);

  const onBreakpointChange = useCallback((newBreakpoint: string) => {
    setCurrentBreakpoint(newBreakpoint);
  }, []);

  const onNewWidgetAdd = useCallback((widget: WidgetConfig, position?: Layout) => {
    resetSelectedWidget();

    if (currentBreakpoint === "lg") {
      setWizardConfig({
        i: '0',
        x: position?.x ?? 0,
        y: position?.y ?? 0,
        w: widget.sizes?.length ? widget.sizes[0].w : 1,
        h: widget.sizes?.length ? widget.sizes[0].h : 1,
      } as Layout);
      setIsWizardOpen(true);
    }
  }, [currentBreakpoint, resetSelectedWidget]);

  const handleOnWizardSelect = useCallback((widget: WidgetConfig) => {
    setIsWizardOpen(false);
    if (wizardConfig) {
      setWidgetToEdit({
        widget: {
          type: widget.type,
        },
        layout: wizardConfig,
      });
      setIsEditOpen(true);
    }
  }, [wizardConfig]);

  const handleOnWidgetEdit = useCallback((widget: Widget) => {
    resetSelectedWidget();
    setWidgetToEdit(widget);
    setIsEditOpen(true);
  }, [resetSelectedWidget]);

  const handleOnWizardClose = useCallback(() => {
    setIsWizardOpen(false);
  }, []);

  const handleOnWidgetEditClose = useCallback(() => {
    setIsEditOpen(false);
  }, []);

  const handleOnWidgetExpand = useCallback((widget: Widget) => {
    setWidgetToExpand(widget);
    setIsExpandOpen(true);
  }, []);

  const handleOnWidgetCollapse = useCallback(() => {
    setWidgetToExpand(null);
    setIsExpandOpen(false);
  }, []);

  const isTouchScreen = useMemo(() => {
    return window.matchMedia("(pointer: coarse)").matches;
  }, []);

  const handleCollisionModalClose = () => {
    setIsCollisionModalOpen(false);
    rollbackChange();
  };

  return (
    <>
      {projectData && gridLayout != null && gridConfig && (
        <ResponsiveReactGridLayout
          className={cn('layout mb-6', gridConfig.classNames)}
          layouts={gridLayout}
          compactType={currentBreakpoint && ['xs', 'sm'].includes(currentBreakpoint) ? "vertical" : "horizontal"}
          onBreakpointChange={onBreakpointChange}
          onResizeStart={onResizeStart}
          onResizeStop={onResizeStop}
          onResize={onResize}
          onDragStart={() => setIsInteracting(true)}
          onDragStop={onDragStop}
          allowOverlap={currentBreakpoint === 'lg' || false}
          isResizable={isEditable}
          draggableCancel=".grid-item-disable-drag"
          preventCollision={currentBreakpoint === 'lg' || false}
          {...gridConfig}
          rowHeight={type === 'default' && gridConfig.rowHeights ? gridConfig.rowHeights[currentBreakpoint] : gridConfig.rowHeight}
        >
          {gridLayout[currentBreakpoint].map((layoutItem: Layout) => {
            const widget = (findWidget(layoutItem.i, layout) as Widget);
            if (!widget) return null;

            return <div key={layoutItem.i} className={cn('group relative', { 'select-none': isInteracting, 'hidden': currentBreakpoint !== "lg" && widget.widget.type === DashboardWidgetType.Placeholder })} data-key={`${currentBreakpoint}-${layoutItem.i}`}>
            {getWidget(widget.widget, 'card', projectData?.project as ProjectReadModel, multiProject, layoutItem, onNewWidgetAdd)}
            {isEditable && widget.widget.type !== DashboardWidgetType.Placeholder && (
              <div className={cn('grid-item-disable-drag absolute top-0 right-0 cursor-pointer p-2 z-50 pointer-events-auto flex', { 'transition-opacity duration-300 opacity-0 group-hover:opacity-100 group-hover:hover:opacity-100': !isTouchScreen})}>
                {widget.widget.type === DashboardWidgetType.MapView && layoutItem.w === 1 && layoutItem.h === 1 && (
                  <div className="me-3" onClick={() => { handleOnWidgetExpand({ ...widget, layout: { ...layoutItem, h: 3 }}); }}>
                    <MaximizeIcon className="w-5 h-auto" />
                  </div>
                )}
                {currentBreakpoint === "lg" && (
                  <div onClick={() => { handleOnWidgetEdit(widget); }}>
                    <PencilIcon className="w-5 h-auto" />
                  </div>
                )}
              </div>
            )}
          </div>;
          })}
        </ResponsiveReactGridLayout>
      )}
      <WidgetDashboardWizard
        widgets={dashboardConfig[type].availableWidgets}
        onSelect={handleOnWizardSelect}
        isOpen={isWizardOpen}
        onClose={handleOnWizardClose}
        wizardConfig={wizardConfig}
        gridLayout={gridLayout}
        dashboardType={type}
      />
      <WidgetDashboardEdit
        dashboardType={type}
        widget={widgetToEdit}
        isOpen={isEditOpen}
        onClose={handleOnWidgetEditClose}
        onAfterLeave={handleOnWidgetEditClose}
        onSaved={handleOnWidgetEditClose}
        dashboardId={dashboardId}
        dashboardName={dashboardName}
        gridLayout={gridLayout}
        layout={layout}
        project={projectData?.project as ProjectReadModel}
      />
      <Modal position="center"
        className="h-[500px]"
        variant={currentBreakpoint === 'lg' ? 'medium' : 'small'}
        showCloseButton={false}
        showMaximizeButton={true}
        isMaximized={true}
        isOpen={isExpandOpen}
        onClose={() => setIsExpandOpen(false)}
        onClickMaximizeButton={handleOnWidgetCollapse}
      >
        <div className="h-full bg-white">
          {widgetToExpand && gridLayout &&  getWidget(widgetToExpand.widget, 'card', projectData?.project as ProjectReadModel, multiProject, { ...widgetToExpand.layout, w: currentBreakpoint === 'lg' ? 3 : widgetToExpand.layout.w })}
        </div>
      </Modal>
      <Modal variant="small-wide" isOpen={isCollisionModalOpen} onClose={handleCollisionModalClose}>
        <Modal.Header
          title={t('dashboard.collisionModal.title')}
          size="small"
        />
        <Modal.Content>
          <p>{t('dashboard.collisionModal.message')}</p>
          <ul className="list-disc ms-4 my-2">
            {invalidGridItems && invalidGridItems.targetItems.map((targetItem: Layout) => {
              return <li key={targetItem.i}><strong>{findWidget(targetItem.i, layout)?.widget.title}</strong></li>
            })}
          </ul>
        </Modal.Content>
        <Modal.Controls className="bg-white justify-center">
          <Button variant="primary" className="mr-2" onClick={rollbackChange}>
            {t('dashboard.collisionModal.rollbackAction')}
          </Button>
          <Button variant="text" className="mr-2" onClick={confirmConflict}>
            {t('dashboard.collisionModal.deleteAction')}
          </Button>
        </Modal.Controls>
      </Modal>
    </>
  );
};
